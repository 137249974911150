<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
              ref="observer"
              v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                          vid="code"
                          :name="beForm[rep].code.label"
                          :label="beForm[rep].code.label"
                          v-model="form[rep].code"
                          placeholder="Inserisci un codice"
                          :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                          vid="title"
                          :name="beForm[rep].title.label"
                          label="Descrizione"
                          v-model="form[rep].title"
                          placeholder="Inserisci una descrizione"
                          :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                          vid="is_system"
                          :name="beForm[rep].is_system.label"
                          label="Utilizza per inserimento manuale"
                          v-model="form[rep].is_system"
                          :options="beForm[rep].is_system.options"
                          :taggable="false"
                          :multiple="false"
                          :closeOnSelect="true"
                          :rules="getRules('is_system')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                    @click="handleSubmit(onSubmit)"
                    type="button"
                    :disabled="invalid"
                    variant="lisaweb"
                    size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                >Reset
                </b-button
                >
                <b-button
                    @click="$router.back()"
                    variant="lisaweb"
                    size="sm"
                    class="float-right"
                >
                  <b-icon-chevron-double-left
                      font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >
                  Torna indietro
                </b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35"/>
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "book_tag",
      id: this.$route.params.id,
      form: {
        book_tag: {
          title: null,
          code: null,
          is_system: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
          .then(() => {
            this.$store.dispatch("auth/book_tags");
            this.$showSnackbar({
              preset: "success",
              text: `Azione Completata: codice documento creato`,
            });
            this.shortcut("book_tags.index", null, null, "filterBookTags");
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
